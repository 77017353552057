import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import About from "./About";




function App() {
  return (
    <>
    <Header />
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        </Routes>
  <Footer />

    </div>

    </>
  );
}

export default App;
