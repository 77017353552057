import React from "react";
import Header1 from "./Header1";
 
const About = () => {
return (
    <>
 <Header1 />
<section className="section-breadcrumb">
            <div className="background-dark">
                <div className="position-relative z-1">
                    <div className="breadcrumb-space">
                        <div className="breadcrumb-container">
                            <div className="breadcrumb-right-block">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="index-2.html">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                                <div className="breadcrumb-content">
                                    <h2 className="text-white">
                                        Elevate Construct: Building Dreams, One Structure at a Time
                                    </h2>
                                    <p>A construction company is a business entity that for this specializes in the
                                        planning,
                                        development.</p>
                                    <a href="contact.html" className="btn-text-icon is-orange-white">
                                        Contact With Us
                                        <span><i className="fa-sharp fa-regular fa-arrow-right-long"></i></span>
                                    </a>
                                </div>
                            </div>
                            <div className="breadcrumb-left-block">
                                <div className="breadcrumb-img">
                                    <img src="assets/img/images/th-1/breadcrumb-img-1.png" alt="breadcrumb-img-1"
                                        width="748" height="395" className="img-responsive" />
                                    <img src="assets/img/abstract/doodles.svg" alt="doodles" width="124" height="86" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bradecrumb-shape">
                        <img src="assets/img/abstract/breadcrumb-bg-shape.svg" alt="breadcrumb-bg-shape" width="203"
                            height="232" />
                    </div>
                </div>

            </div>
        </section>

        <section className="section-why-us">
    <div className="position-relative z-1">
        <div className="section-space">
            <div className="container">
                <div className="row gy-5 flex-column-reverse flex-lg-row align-items-center">
                    <div className="col-lg-5 col-xxl-6">
                        <img src="assets/img/images/th-2/why-us-img-1.png" alt="why-us-img" width="648" height="619" className="jos img-fluid" />
                    </div>
                    <div className="col-lg-7 col-xxl-6 ps-lg-5">
                        <div className="section-block">
                            <h2 className="jos section-title">Build Your Better Home With Professional Trusted
                                Services
                            </h2>
                            <p className="jos section-text">A construction company is a business entity that
                                specializes
                                in the planning, development, and execution of construction projects.</p>
                        </div>
                        <div className="horizontal-line is-1 mb-5"></div>
                        <ul className="content-list">
                            <li className="jos">
                                <h3><img src="assets/img/icons/icon-orange-check.svg" alt="icon-orange-check" />Always support from our expert from member</h3>
                                <p>Pellentesque vestibulum, nisi quis posuere pharetra, ligula mauris ultricies
                                    metus, vel malesuada leo urna et neque. Donec aliquet sed mauris nec porta
                                </p>
                            </li>
                            <li className="jos">
                                <h3><img src="assets/img/icons/icon-orange-check.svg" alt="icon-orange-check" />High Quality Work & Satisfaction Guarantee</h3>
                                <p>Pellentesque vestibulum, nisi quis posuere pharetra, ligula mauris ultricies
                                    metus, vel malesuada leo urna et neque. Donec aliquet sed mauris nec porta
                                </p>
                            </li>
                        </ul>
                        <div className="mt-5">
                            <a href="about.html" className="btn btn-orange">More About Konsta</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="section-about">
    <div className="section-space-bottom">
        <div className="container">
            <div className="row">
                <div className="col-xl-10 justify-items-center">
                    <h2 className="jos">Construction company are responsible for overseeing & and managing all
                        aspects of a
                        construction project. This includes planning, scheduling, budgeting, and coordination of
                        resources.</h2>
                    <div className="horizontal-line is-1 my-5"></div>
                    <div className="row justify-content-between gy-3">
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="63">63</span>m
                            </div>
                            <span>Trusted By People</span>
                        </div>
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="34">34</span>+
                            </div>
                            <span>Years Of Business</span>
                        </div>
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="5">5</span>k+
                            </div>
                            <span>Trusted By People</span>
                        </div>
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="39">39</span>+
                            </div>
                            <span>Our Team Member</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="section-why-choose">
    <div className="section-space-bottom">
        <div className="container">
            <div className="row g-0">
                <div className="col-lg-6 col-xxl-8">
                    <img src="assets/img/images/th-2/why-us-img-2.png" alt="why-us-img-2" width="872" height="508" className="img-cover" />
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <div className="why-us-block--2">
                        <ul>
                            <li>
                                <div className="content-top">
                                    <img src="assets/img/icons/icon-white-award.svg" alt="icon-white-award" width="58" height="50" />
                                    <h3>We Are A Award Winning Company</h3>
                                </div>
                                <p>We are <b>100+</b> professional software is the that engineers with more is a
                                    than.</p>
                            </li>
                            <li>
                                <div className="content-top">
                                    <img src="assets/img/icons/icon-orange-salary.svg" alt="icon-orange-salary" width="58" height="50" />
                                    <h3>633,56+ Solution Solved Now</h3>
                                </div>
                                <p>We are 100+ professional software is that engineers with more is a than.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="section-why-us">
    <div className="section-space-bottom">
        <div className="container">
            <div className="row gy-5 flex-column-reverse flex-lg-row align-items-center">
                <div className="col-lg-5 col-xxl-7">
                    <img src="assets/img/images/th-1/why-us-img.png" alt="why-us-img" className="jos img-fluid" data-jos_animation="fade-right" />
                </div>
                <div className="col-lg-7 col-xxl-5 ps-lg-5">
                    <div className="section-block">
                        <h2 className="jos section-title">Engineering Excellence, Constructing Tomorrow: Konsta
                            Builders
                        </h2>
                        <p className="jos section-text">A construction company is a business entity that specializes
                            in
                            the planning, development, and execution of construction projects. These projects
                            can range from residential, commercial buildings to infrastructure such as roads,
                            bridges, and utilities.</p>
                    </div>
                    <ul className="content-list">
                        <li className="jos">
                            <h3><img src="assets/img/icons/icon-orange-doc.svg" alt="icon-orange-doc" /> Best
                                House Renovation</h3>
                            <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                ratio of debt to income, among other things.</p>
                        </li>
                        <li className="jos">
                            <h3><img src="assets/img/icons/icon-orange-home.svg" alt="icon-orange-doc" /> Quality
                                Always Matters</h3>
                            <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                ratio of debt to income, among other things.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="section-project">
    <div className="project-showcase-block">
        <div className="container">
            <div className="row">
                <div className="jos col-12 col-md-6 col-lg-4">
                    <div className="project-showcase-single-item">
                        <div className="project-number">01</div>
                        <div className="project-bottom-content">
                            <div className="project-tags">
                                <a href="project-details.html" className="project-tag-link">Web Application</a>
                                <a href="project-details.html" className="project-tag-link">Elicitation</a>
                            </div>
                            <h2 className="text-white">Medical Office</h2>
                        </div>
                    </div>
                </div>
                <div className="jos col-12 col-md-6 col-lg-4">
                    <div className="project-showcase-single-item">
                        <div className="project-number">02</div>
                        <div className="project-bottom-content">
                            <div className="project-tags">
                                <a href="project-details.html" className="project-tag-link">Web Application</a>
                                <a href="project-details.html" className="project-tag-link">Elicitation</a>
                            </div>
                            <h2 className="text-white">Residential Office</h2>
                        </div>
                    </div>
                </div>
                <div className="jos col-12 col-md-6 col-lg-4">
                    <div className="project-showcase-single-item">
                        <div className="project-number">03</div>
                        <div className="project-bottom-content">
                            <div className="project-tags">
                                <a href="project-details.html" className="project-tag-link">Web Application</a>
                                <a href="project-details.html" className="project-tag-link">Elicitation</a>
                            </div>
                            <h2 className="text-white">House Extension</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/img/images/th-1/project-bg-1.jpg" alt="project-bg-1" width="1920" height="699" className="project-background" />
    </div>
</section>

<section className="section-blog">
    <div className="section-space">
        <div className="container">
            <div className="row justify-content-between align-items-center g-4">
                <div className="col-sm-10 col-md-7 col-xxl-5">
                    <h2 className="section-title">
                        Update With Our Latest Trends & Insights
                    </h2>
                </div>
                <div className="col-auto">
                    <a href="blogs.html" className="btn btn-orange">View All News</a>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <ul className="blog-list--2">
                        <li className="jos blog-item">
                            <div className="blog-post-meta">
                                <a href="#">
                                    <img src="assets/img/icons/icon-orange-user.svg" alt="icon-orange-user" width="16" height="16" />
                                    Admin
                                </a>
                                <a href="#">
                                    <img src="assets/img/icons/icon-orange-chat.svg" alt="icon-orange-chat" width="16" height="16" />
                                    Comment
                                </a>
                            </div>
                            <a href="blog-details.html" className="blog-title">Create your digital branding with
                                expert team member</a>
                            <a href="blog-details.html" className="blog-thumbnail">
                                <img src="assets/img/images/th-2/blog-thumbnail-1.jpg" alt="blog-thumbnail-1" width="374" height="268" className="img-responsive" />
                            </a>
                            <p>Our mission is to deliver creative and innovative that solutions that help
                                businesses succeed.</p>
                            <a href="blog-details.html" className="btn-text-icon is-orange">
                                More Details
                                <span><i className="fa-sharp fa-regular fa-arrow-right-long"></i></span>
                            </a>
                        </li>
                        <li className="jos blog-item">
                            <div className="blog-post-meta">
                                <a href="#">
                                    <img src="assets/img/icons/icon-orange-user.svg" alt="icon-orange-user" width="16" height="16" />
                                    Admin
                                </a>
                                <a href="#">
                                    <img src="assets/img/icons/icon-orange-chat.svg" alt="icon-orange-chat" width="16" height="16" />
                                    Comment
                                </a>
                            </div>
                            <a href="blog-details.html" className="blog-title">How to build up your building with
                                top rated construction company</a>
                            <a href="blog-details.html" className="blog-thumbnail">
                                <img src="assets/img/images/th-2/blog-thumbnail-2.jpg" alt="blog-thumbnail-2" width="374" height="268" className="img-responsive" />
                            </a>
                            <p>Our mission is to deliver creative and innovative that solutions that help
                                businesses succeed.</p>
                            <a href="blog-details.html" className="btn-text-icon is-orange">
                                More Details
                                <span><i className="fa-sharp fa-regular fa-arrow-right-long"></i></span>
                            </a>
                        </li>
                        <li className="jos blog-item">
                            <div className="blog-post-meta">
                                <a href="#">
                                    <img src="assets/img/icons/icon-orange-user.svg" alt="icon-orange-user" width="16" height="16" />
                                    Admin
                                </a>
                                <a href="#">
                                    <img src="assets/img/icons/icon-orange-chat.svg" alt="icon-orange-chat" width="16" height="16" />
                                    Comment
                                </a>
                            </div>
                            <a href="blog-details.html" className="blog-title">Grow your building with high safety
                                for your next generation</a>
                            <a href="blog-details.html" className="blog-thumbnail">
                                <img src="assets/img/images/th-2/blog-thumbnail-3.jpg" alt="blog-thumbnail-3" width="374" height="268" className="img-responsive" />
                            </a>
                            <p>Our mission is to deliver creative and innovative that solutions that help
                                businesses succeed.</p>
                            <a href="blog-details.html" className="btn-text-icon is-orange">
                                More Details
                                <span><i className="fa-sharp fa-regular fa-arrow-right-long"></i></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

    </>
)
};
 
export default About;