import React from "react";
 
const Home = () => {
return (
   <div>

<section className="section-hero">
            <div className="position-relative z-1 overflow-hidden">
                <div className="hero-container">
                    <div className="hero-area hero-area--1">
                        <div className="hero-content-block hero-content-block--1">
                            <h1>Build Your Dream Buildings For Future</h1>
                            <p>Bring to the table win-win survival strategies to ensure proactive domination.</p>
                            <a href="services.html" className="btn btn-primary">View Our Services</a>
                        </div>
                        <div className="hero-image-block hero-image-block--1">
                            <img src="assets/img/images/th-1/hero-img-1.png" alt="hero-img-1" width="501" height="516"
                                className="img-fluid" />
                            <img src="assets/img/images/th-1/hero-img-bg-shape.png" alt="hero-img-bg-shape" width="565"
                                height="649" className="hero-shape-img hero-shape-img--1" />
                        </div>
                    </div>
                </div>

                <div className="hero-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col position-relative">
                                <img src="assets/img/images/th-1/hero-banner-img.png" alt="hero-banner-img" width="1320"
                                    height="563" className="img-cover" />
                                <img src="assets/img/abstract/doodles.svg" alt="doodles" width="182" height="126"
                                    className="position-absolute z-1 end-0 bottom-0 mb-5 d-none d-md-inline-block" />
                            </div>
                        </div>
                    </div>
                    <div className="hero-stripe-1"></div>
                    <img src="assets/img/abstract/construction-cart.svg" alt="construction-cart" height="137" width="91"
                        className="abstract-img-hero--1" />
                </div>
            </div>
        </section>

        <section className="section-about">
    <div className="section-space">
        <div className="container">
            <div className="row">
                <div className="col-xl-10 justify-items-center">
                    <h2 className="jos">Construction company are responsible for overseeing & and managing all
                        aspects of a
                        construction project. This includes planning, scheduling, budgeting, and coordination of
                        resources.</h2>
                    <div className="horizontal-line is-1 my-5"></div>
                    <div className="row justify-content-between gy-3">
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="63">63</span>m
                            </div>
                            <span>Trusted By People</span>
                        </div>
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="34">34</span>+
                            </div>
                            <span>Years Of Business</span>
                        </div>
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="5">5</span>k+
                            </div>
                            <span>Trusted By People</span>
                        </div>
                        <div className="jos col-auto">
                            <div className="countup-text" data-module="countup">
                                <span className="start-number" data-countup-number="39">39</span>+
                            </div>
                            <span>Our Team Member</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="section-why-us">
    <div className="section-space-bottom">
        <div className="container">
            <div className="row gy-5 flex-column-reverse flex-lg-row align-items-center">
                <div className="col-lg-5 col-xxl-7">
                    <img src="assets/img/images/th-1/why-us-img.png" alt="why-us-img" className="jos img-fluid" data-jos_animation="fade-right" />
                </div>
                <div className="col-lg-7 col-xxl-5 ps-lg-5">
                    <div className="section-block">
                        <h2 className="jos section-title">Engineering Excellence, Constructing Tomorrow: Konsta
                            Builders
                        </h2>
                        <p className="jos section-text">A construction company is a business entity that specializes
                            in
                            the planning, development, and execution of construction projects. These projects
                            can range from residential, commercial buildings to infrastructure such as roads,
                            bridges, and utilities.</p>
                    </div>
                    <ul className="content-list">
                        <li className="jos">
                            <h3><img src="assets/img/icons/icon-orange-doc.svg" alt="icon-orange-doc" /> Best
                                House Renovation</h3>
                            <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                ratio of debt to income, among other things.</p>
                        </li>
                        <li className="jos">
                            <h3><img src="assets/img/icons/icon-orange-home.svg" alt="icon-orange-doc" /> Quality
                                Always Matters</h3>
                            <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                ratio of debt to income, among other things.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div className="section-services">
    <div className="section-space-bottom">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="section-bg-dark service-wrapper--1">
                        <div className="row gy-5">
                            <div className="col-lg-6">
                             
                                <ul className="service-list-text">
                                    <li className="jos">
                                        <a href="service-details.html">Integrity Construction <span><i className="fa-sharp fa-light fa-arrow-right-long"></i></span></a>
                                    </li>
                                    <li className="jos">
                                        <a href="service-details.html">Property Maintenance <span><i className="fa-sharp fa-light fa-arrow-right-long"></i></span></a>
                                    </li>
                                    <li className="jos">
                                        <a href="service-details.html">Virtual Design & Build <span><i className="fa-sharp fa-light fa-arrow-right-long"></i></span></a>
                                    </li>
                                    <li className="jos">
                                        <a href="service-details.html">General Construction <span><i className="fa-sharp fa-light fa-arrow-right-long"></i></span></a>
                                    </li>
                                    <li className="jos">
                                        <a href="service-details.html">Roof Solar Panels <span><i className="fa-sharp fa-light fa-arrow-right-long"></i></span></a>
                                    </li>
                                </ul>
                              
                            </div>
                            <div className="col-lg-6">
                                <div className="service-block-right">
                                    <p className="jos text-color-light">
                                        A construction company is a business entity that specializes in the
                                        planning, development, and execution of construction projects. These
                                        projects can range from residential.
                                    </p>
                                    <a href="services.html" className="jos btn-text-icon is-blue">
                                        More Details
                                        <span><i className="fa-sharp fa-regular fa-arrow-right-long"></i></span>
                                    </a>
                                    <div className="jos service-img">
                                        <img src="assets/img/images/th-1/service-img.png" alt="service-img" width="512" height="517" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-5 ps-5">
                        <a href="services.html" className="btn-text-icon is-orange">
                            More Details
                            <span><i className="fa-sharp fa-regular fa-arrow-right-long"></i></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section className="section-why-us">
    <div className="position-relative z-1">
        <div className="section-space">
            <div className="container">
                <div className="row gy-5 flex-column-reverse flex-lg-row align-items-center">
                    <div className="col-lg-5 col-xxl-6">
                        <img src="assets/img/images/th-1/why-us-img-2.png" alt="why-us-img" className="jos img-fluid" />
                    </div>
                    <div className="col-lg-7 col-xxl-6 ps-lg-5">
                        <div className="section-block">
                            <h2 className="jos section-title">Build Your Better Home With Professional Trusted
                                Services
                            </h2>
                            <p className="jos section-text">A construction company is a business entity that
                                specializes
                                in the planning, development, and execution of construction projects.</p>
                        </div>
                        <div className="horizontal-line is-1 mb-5"></div>
                        <ul className="content-list">
                            <li className="jos">
                                <h3><img src="assets/img/icons/icon-orange-check.svg" alt="icon-orange-check" />Always support from our expert from member</h3>
                                <p>Pellentesque vestibulum, nisi quis posuere pharetra, ligula mauris ultricies
                                    metus, vel malesuada leo urna et neque. Donec aliquet sed mauris nec porta
                                </p>
                            </li>
                            <li className="jos">
                                <h3><img src="assets/img/icons/icon-orange-check.svg" alt="icon-orange-check" />High Quality Work & Satisfaction Guarantee</h3>
                                <p>Pellentesque vestibulum, nisi quis posuere pharetra, ligula mauris ultricies
                                    metus, vel malesuada leo urna et neque. Donec aliquet sed mauris nec porta
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/img/abstract/absturct-1.svg" alt="absturct-1" width="119" height="82" className="why-us-abstruct--1" />
        <img src="assets/img/abstract/absturct-2.svg" alt="absturct-2" width="106" height="92" className="why-us-abstruct--2" />
    </div>
</section>

<section className="section-project">
    <div className="project-showcase-block">
        <div className="container">
            <div className="row">
                <div className="jos col-12 col-md-6 col-lg-4">
                    <div className="project-showcase-single-item">
                        <div className="project-number">01</div>
                        <div className="project-bottom-content">
                            <div className="project-tags">
                                <a href="project-details.html" className="project-tag-link">Web Application</a>
                                <a href="project-details.html" className="project-tag-link">Elicitation</a>
                            </div>
                            <h2 className="text-white">Medical Office</h2>
                        </div>
                    </div>
                </div>
                <div className="jos col-12 col-md-6 col-lg-4">
                    <div className="project-showcase-single-item">
                        <div className="project-number">02</div>
                        <div className="project-bottom-content">
                            <div className="project-tags">
                                <a href="project-details.html" className="project-tag-link">Web Application</a>
                                <a href="project-details.html" className="project-tag-link">Elicitation</a>
                            </div>
                            <h2 className="text-white">Residential Office</h2>
                        </div>
                    </div>
                </div>
                <div className="jos col-12 col-md-6 col-lg-4">
                    <div className="project-showcase-single-item">
                        <div className="project-number">03</div>
                        <div className="project-bottom-content">
                            <div className="project-tags">
                                <a href="project-details.html" className="project-tag-link">Web Application</a>
                                <a href="project-details.html" className="project-tag-link">Elicitation</a>
                            </div>
                            <h2 className="text-white">House Extension</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/img/images/th-1/project-bg-1.jpg" alt="project-bg-1" width="1920" height="699" className="project-background" />
    </div>
</section>
<section className="section-testimonial">
    <div className="section-space">
        <div className="container">
            <div className="row gy-4 justify-content-between align-items-center">
                <div className="col-xl-6">
                    <h2 className="jos section-title">What People Say About Our Construction Company</h2>
                </div>
                <div className="col-xl-5">
                    <p className="jos section-text mt-0">Construction company are responsible for overseeing & and
                        managing all aspects of a construction project. This includes planning, scheduling,
                        budgeting, and coordination of resources.</p>
                </div>
            </div>
            <div className="row g-0 mt-5">
                <div className="col-3 d-none d-xl-block">
                    <div className="testimonial-bg-orange">
                        <img src="assets/img/images/th-1/testimonial-img.png" alt="testimonial-img" width="303" height="429" />
                    </div>
                </div>
                <div className="col-12 col-xl-9">
                    <div className="testimonial-area--1">
                        <div className="testimonial-slider-1 swiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-block-item--1">
                                        <div className="review-star">
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                        </div>
                                        <blockquote>Voluptatem accusantium doloremqu laudantium, totam rem
                                            aperiam, eaque ipsa quae ab illo dolore inventore veritatis et quasi
                                            architectoiy beatae set vitae dicta sunt explicabo. Nemo enim ipsam
                                            amy dolor sit voluptatem quia voluptas sit aspernatur aut oditit aut
                                            fugit, </blockquote>
                                        <div className="testimonial-user-info">
                                            <img src="assets/img/images/th-1/testimonial-user-1.png" alt="testimonial-user-1" width="55" height="56" />
                                            <div className="testimonial-user-content">
                                                <div className="testimonial-user-name">William Smith</div>
                                                <span>02 Days ago</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-block-item--1">
                                        <div className="review-star">
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                            <img src="assets/img/icons/icon-orange-star.svg" alt="icon-orange-star" width="25" height="25" />
                                        </div>
                                        <blockquote>Voluptatem accusantium doloremqu laudantium, totam rem
                                            aperiam, eaque ipsa quae ab illo dolore inventore veritatis et quasi
                                            architectoiy beatae set vitae dicta sunt explicabo. Nemo enim ipsam
                                            amy dolor sit voluptatem quia voluptas sit aspernatur aut oditit aut
                                            fugit, </blockquote>
                                        <div className="testimonial-user-info">
                                            <img src="assets/img/images/th-1/testimonial-user-1.png" alt="testimonial-user-1" width="55" height="56" />
                                            <div className="testimonial-user-content">
                                                <div className="testimonial-user-name">William Smith</div>
                                                <span>02 Days ago</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-button-block">
                                <div className="testimonial-button-prev">
                                    <img src="assets/img/icons/icon-white-long-left-arrow.svg" alt="icon-white-long-left-arrow" width="30" height="31" />
                                </div>
                                <div className="testimonial-button-next">
                                    <img src="assets/img/icons/icon-white-long-right-arrow.svg" alt="icon-white-long-right-arrow" width="30" height="31" />
                                </div>
                            </div>
                        </div>
                        <div className="triangle-tl-shape"></div>
                        <div className="triangle-br-shape"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="work-progress">
            <div className="section-space-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="section-block">
                                <h2 className="jos section-title text-center">Our Working Plan With Best Experience</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul className="work-progress-list">
                                <li className="jos" data-jos_animation="fade-right" data-jos_delay="0">
                                    <div className="work-progress-icon">
                                        <img src="assets/img/icons/icon-work-process-1.svg" alt="icon-work-process-1"
                                            width="32" height="32" />
                                        <img src="assets/img/icons/icon-work-process-1-hover.svg"
                                            alt="icon-work-process-1" width="32" height="32" />
                                    </div>
                                    <div className="work-progress-content">
                                        <h3>Product Research</h3>
                                        <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                            ratio of debt to income, among other things.</p>
                                    </div>
                                    <div className="work-progress-number">01</div>
                                </li>
                                <li className="jos" data-jos_animation="fade-right" data-jos_delay="0.3">
                                    <div className="work-progress-icon">
                                        <img src="assets/img/icons/icon-work-process-2.svg" alt="icon-work-process-2"
                                            width="32" height="32" />
                                        <img src="assets/img/icons/icon-work-process-2-hover.svg"
                                            alt="icon-work-process-2" width="32" height="32" />
                                    </div>
                                    <div className="work-progress-content">
                                        <h3>General Contract</h3>
                                        <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                            ratio of debt to income, among other things.</p>
                                    </div>
                                    <div className="work-progress-number">02</div>
                                </li>
                                <li className="jos" data-jos_animation="fade-right" data-jos_delay="0.6">
                                    <div className="work-progress-icon">
                                        <img src="assets/img/icons/icon-work-process-3.svg" alt="icon-work-process-3"
                                            width="32" height="32" />
                                        <img src="assets/img/icons/icon-work-process-3-hover.svg"
                                            alt="icon-work-process-3" width="32" height="32" />
                                    </div>
                                    <div className="work-progress-content">
                                        <h3>Project Planning</h3>
                                        <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                            ratio of debt to income, among other things.</p>
                                    </div>
                                    <div className="work-progress-number">03</div>
                                </li>
                                <li className="jos" data-jos_animation="fade-right" data-jos_delay="0.9">
                                    <div className="work-progress-icon">
                                        <img src="assets/img/icons/icon-work-process-4.svg" alt="icon-work-process-4"
                                            width="32" height="32" />
                                        <img src="assets/img/icons/icon-work-process-4-hover.svg"
                                            alt="icon-work-process-4" width="32" height="32" />
                                    </div>
                                    <div className="work-progress-content">
                                        <h3>Start Your Design</h3>
                                        <p>Amount a lender is willing to lend to financing your purchase as fact in your
                                            ratio of debt to income, among other things.</p>
                                    </div>
                                    <div className="work-progress-number">04</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-team">
    <div className="relative z-1">
        <div className="background-orange container-outer-background have-triangle-shape">
            <div className="section-space-top">
                <div className="container">
                    <div className="row justify-content-between align-items-center gy-4 mb-5">
                        <div className="jos col-12 col-sm-10 col-md-8 col-lg-6">
                            <h2 className=" section-title text-white">Meet With Our Expert Team Member In Working
                            </h2>
                        </div>
                        <div className="jos col-auto">
                            <a href="teams.html" className="btn btn-dark">View All Member</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ul className="team-list--1">
                                <li className="jos team-item">
                                    <div className="team-thumbnails">
                                        <img src="assets/img/images/th-1/team-img-1.jpg" alt="team-img-1" width="424" height="392" className="img-cover" />
                                        <div className="team-social-links">
                                            <a href="http://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </a>
                                            <a href="http://www.pinterest.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-pinterest-p"></i>
                                            </a>
                                            <a href="http://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-instagram"></i>
                                            </a>
                                            <a href="http://www.x.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-x-twitter"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="team-content">
                                        <span>Industrial Engineer</span>
                                        <h3>Richard Jackson</h3>
                                        <p>Amount a lender is willing lend to financing your purchase as fact in
                                            your ratio of debt to income, among other things.</p>
                                    </div>
                                </li>
                                <li className="jos team-item">
                                    <div className="team-thumbnails">
                                        <img src="assets/img/images/th-1/team-img-2.jpg" alt="team-img-2" width="424" height="392" className="img-cover" />
                                        <div className="team-social-links">
                                            <a href="http://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </a>
                                            <a href="http://www.pinterest.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-pinterest-p"></i>
                                            </a>
                                            <a href="http://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-instagram"></i>
                                            </a>
                                            <a href="http://www.x.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-x-twitter"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="team-content">
                                        <span>Industrial Engineer</span>
                                        <h3>Michael Anderson</h3>
                                        <p>Amount a lender is willing lend to financing your purchase as fact in
                                            your ratio of debt to income, among other things.</p>
                                    </div>
                                </li>
                                <li className="jos team-item">
                                    <div className="team-thumbnails">
                                        <img src="assets/img/images/th-1/team-img-3.jpg" alt="team-img-3" width="424" height="392" className="img-cover" />
                                        <div className="team-social-links">
                                            <a href="http://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </a>
                                            <a href="http://www.pinterest.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-pinterest-p"></i>
                                            </a>
                                            <a href="http://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-instagram"></i>
                                            </a>
                                            <a href="http://www.x.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-x-twitter"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="team-content">
                                        <span>Industrial Engineer</span>
                                        <h3>Jennifer Brown</h3>
                                        <p>Amount a lender is willing lend to financing your purchase as fact in
                                            your ratio of debt to income, among other things.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="section-blog">
    <div className="section-space section-space-xlarge-top">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 mx-auto">
                    <div className="section-block">
                        <h2 className="jos section-title text-center">
                            Update With Our Latest Trends & Insights
                        </h2>
                    </div>
                </div>
                <div className="col-12">
                    <ul className="blog-list--1">
                        <li className="jos blog-item">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-5">
                                    <div className="blog-left">
                                        <div className="blog-post-meta">
                                            <a href="#">
                                                <img src="assets/img/icons/icon-orange-user.svg" alt="icon-orange-user" width="16" height="16" />
                                                Admin
                                            </a>
                                            <a href="#">
                                                <img src="assets/img/icons/icon-orange-chat.svg" alt="icon-orange-chat" width="16" height="16" />
                                                Comment
                                            </a>
                                        </div>
                                        <a href="blog-details.html" className="blog-title">Super Bowl ad features a
                                            literally striking visual full self-driving</a>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="blog-center">
                                        <p>We used analyzing your request and formulating the task according to
                                            your
                                            exacts needs to find the most relevent solution</p>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="blog-right">
                                        <a href="blog-details.html">
                                            <span><i className="fa-sharp fa-regular fa-arrow-up-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="jos blog-item">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-5">
                                    <div className="blog-left">
                                        <div className="blog-post-meta">
                                            <a href="#">
                                                <img src="assets/img/icons/icon-orange-user.svg" alt="icon-orange-user" width="16" height="16" />
                                                Admin
                                            </a>
                                            <a href="#">
                                                <img src="assets/img/icons/icon-orange-chat.svg" alt="icon-orange-chat" width="16" height="16" />
                                                Comment
                                            </a>
                                        </div>
                                        <a href="blog-details.html" className="blog-title">Top 12 tips to building
                                            your home awesome in the last year of 2024</a>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="blog-center">
                                        <p>We used analyzing your request and formulating the task according to
                                            your
                                            exacts needs to find the most relevent solution</p>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="blog-right">
                                        <a href="blog-details.html">
                                            <span><i className="fa-sharp fa-regular fa-arrow-up-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="jos blog-item">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-5">
                                    <div className="blog-left">
                                        <div className="blog-post-meta">
                                            <a href="#">
                                                <img src="assets/img/icons/icon-orange-user.svg" alt="icon-orange-user" width="16" height="16" />
                                                Admin
                                            </a>
                                            <a href="#">
                                                <img src="assets/img/icons/icon-orange-chat.svg" alt="icon-orange-chat" width="16" height="16" />
                                                Comment
                                            </a>
                                        </div>
                                        <a href="blog-details.html" className="blog-title">How to satisfied to your
                                            own property with your happy family to stay</a>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="blog-center">
                                        <p>We used analyzing your request and formulating the task according to
                                            your
                                            exacts needs to find the most relevent solution</p>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="blog-right">
                                        <a href="blog-details.html">
                                            <span><i className="fa-sharp fa-regular fa-arrow-up-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>


</div>
)
};
 
export default Home;