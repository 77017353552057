import React from "react";
 
const Header = () => {
  return (
   <div>
     <header className="section-header">
    <div className="header-wrapper">
        <div className="header-logo">
            <a href="index-2.html">
                <img src="assets/img/logo.png" alt="logo" width="174" height="43" />
            </a>
        </div>
        <div className="header-container">
            <div className="header-area">
                <div className="header-top">
                    <div className="header-top-left-side">
                        <ul className="header-info-list">
                            <li>
                                <img src="assets/img/icons/icon-orange-map-pin-fill.svg" alt="icon-orange-map-pin-fill" width="18" height="19" />
                                <address>1200 Main St. Santa Rosa, CA 93541</address>
                            </li>
                            <li>
                                <img src="assets/img/icons/icon-orange-message-2-fill.svg" alt="icon-orange-message-2-fill" width="18" height="18" />
                                <a href="mailto:youremail@mail.com">youremail@mail.com</a>
                            </li>
                            <li>
                                <img src="assets/img/icons/icon-orange-calendar-schedule-fill.svg" alt="icon-orange-calendar-schedule-fill" width="18" height="19" />
                                <span>Mon - Sat 8:00 - 17:30</span>
                            </li>
                        </ul>
                    </div>
                    <div className="header-top-right-side">
                        <span>Follow Us:</span>
                        <div className="header-social-links">
                            <a href="http://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                            <a href="http://www.pinterest.com/" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-pinterest-p"></i>
                            </a>
                            <a href="http://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a href="http://www.x.com/" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-x-twitter"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="horizontal-line is-1"></div>
                <div className="header-bottom">
                    <nav className="header-nav d-none d-lg-block">
                        <ul className="header-nav-list">
  
                            <li className="header-nav-item">
                                <a href="/" className="header-nav-link">
                                    Home
                                </a>
                            </li>
                            <li className="header-nav-item">
                                <a href="/about" className="header-nav-link">
                                    About
                                </a>
                            </li>
                            <li className="header-nav-item">
                                <a href="/about" className="header-nav-link">
                                    Service
                                </a>
                            </li>
                            <li className="header-nav-item">
                                <a href="/about" className="header-nav-link">
                                    Projects
                                </a>
                            </li>
                            <li className="header-nav-item">
                                <a href="/about" className="header-nav-link">
                                    Blogs
                                </a>
                            </li>
                            <li className="header-nav-item">
                                <a href="/about" className="header-nav-link">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <ul className="header-event">
                        <li>
                            <button className="text-dark" type="button" data-bs-toggle="offcanvas" data-bs-target="#searchBar" aria-controls="searchBar">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </li>
                        <li className="d-none d-xxl-block">
                            <div>
                                <i className="fa-solid fa-phone"></i>
                                <a href="tel:+1332455533" className="ps-2">+1 3324 55 533</a>
                            </div>
                        </li>
                        <li>
                            <a href="contact.html" className="btn btn-outline-dark ms-xxl-5 d-none d-xl-inline-block">
                                Let's talk
                            </a>
                        </li>
                        <li>
                            <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasManu" aria-controls="offcanvasManu">
                                <i className="fa-regular fa-bars"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
<div className="offcanvas offcanvas-start block-bg-dark" tabindex="-1" id="offcanvasManu" aria-labelledby="offcanvasManu">
    <div className="offcanvas-header">
        <button type="button" className="btn-close btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        <a href="index-2.html" className="d-flex justify-content-center mb-4">
            <img src="assets/img/logo-orange.png" alt="logo" width="128" height="35" className="img-fluid" />
        </a>
        <nav className="category-menu">
            <ul>
               
                <li className="category">
                    <a href="#">pages</a>
                    <ul className="subcategory">
                        <li>
                            <a href="about.html">About</a>
                        </li>
                        <li>
                            <a href="pricing.html">Pricing</a>
                        </li>
                        <li>
                            <a href="teams.html">Team</a>
                        </li>
                        <li>
                            <a href="team-details.html">Team Details</a>
                        </li>
                        <li>
                            <a href="faqs.html">FAQ’s</a>
                        </li>
                    </ul>
                </li>
                <li className="category">
                    <a href="#">Projects</a>
                    <ul className="subcategory">
                        <li>
                            <a href="projects.html">Projects</a>
                        </li>
                        <li>
                            <a href="project-details.html">Projects Details</a>
                        </li>
                    </ul>
                </li>
                <li className="category">
                    <a href="#">Services</a>
                    <ul className="subcategory">
                        <li>
                            <a href="services.html">Services</a>
                        </li>
                        <li>
                            <a href="service-details.html">Service Details</a>
                        </li>
                    </ul>
                </li>
                <li className="category">
                    <a href="#">News</a>
                    <ul className="subcategory">
                        <li>
                            <a href="blogs.html">News</a>
                        </li>
                        <li>
                            <a href="blog-details.html">News Details</a>
                        </li>
                    </ul>
                </li>
                <li className="category">
                    <a href="/">Home</a>
                </li>
                <li className="category">
                    <a href="/about">About</a>
                </li>
            </ul>
        </nav>
        <div className="social-list justify-content-center mt-3">
            <a href="http://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a href="http://www.pinterest.com/" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-pinterest-p"></i>
            </a>
            <a href="http://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="http://www.x.com/" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-x-twitter"></i>
            </a>
        </div>
    </div>
</div>
<div className="offcanvas offcanvas-top" tabindex="-1" id="searchBar" aria-labelledby="searchBarLabel">
    <div className="offcanvas-header">
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        <div className="container">
            <div className="row">
                <div className="col col-lg-8 mx-auto">
                    <h5 className="offcanvas-title mb-2" id="searchBarLabel">Search by keyword</h5>
                    <form action="#" method="get" className="position-relative">
                        <input type="search" placeholder="Search here" className="form-input pe-5" required />
                        <button className="search-button"><i className="fa-solid fa-magnifying-glass"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
  
        
      


</div>
  )
};
 
export default Header;